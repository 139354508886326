document.addEventListener("turbo:load", function() {
  document.querySelector('#tasks.edit') && tasks.edit()
});

var tasks = {

  edit() {
    $('.ui.calendar').calendar({ type: 'date' });
    $('.ui.dropdown').dropdown({ placeholder: false });
  },

  finished_toggle() {
    $("#tasks.index .finished.toggle").click(function() {
      $.ajax({
        url: "/tasks/" + $(this).data("id") + "/finished",
        type: 'POST',
      }).done(function(result) {
        search.perform("tasks")
      }).fail(function() {
        console.log("error")
      });
    })
  },

  task_popup() {
    $(".main.menu .tasks").popup({
      delay: {
        show: 200
      },
      position: 'bottom center',
      html: '<div style="width: 500px; height: 300px;"><div class="ui active inverted dimmer"><div class="ui text loader">Loading</div></div><p></p></div>',
      on: 'click',
      exclusive: true,
      inline: true,
      offset: -8,
      onShow(el) {
        const popup = this;
        tasks.load_new_task(popup);
      }
    });
  },

  load_new_task(popup) {
    return $.ajax({
      url: '/tasks/new?popup=true',
    }).done(function(result) {
      popup.html(result);
      tasks.edit()
    }).fail(function() {
      popup.html('error');
    });
  },

  init_index() {
    tasks.finished_toggle()
  }
}

window.tasks = tasks;
