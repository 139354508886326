document.addEventListener("turbo:load", function() {
  if ($("#projects.show").length > 0) {
    $("#projects.show .ui.menu .item").tab()
  }
});

var projects = {
  edit() {
    $('.ui.dropdown').not(".parameter").dropdown({ placeholder: false });
    $('.ui.calendar').calendar({ type: 'date' });
  }
}

window.projects = projects;
