import { StreamActions } from "@hotwired/turbo"
StreamActions.toast = function() {  
  const getAllAttributes = el => el
  .getAttributeNames()
  .reduce((obj, name) => ({
    ...obj,
    [name]: el.getAttribute(name)
  }), {})
  let { options } = getAllAttributes(this)
  $.toast({
    ...JSON.parse(options)
  })
}
