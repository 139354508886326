document.addEventListener("turbo:load", function() {

});

var users = {
 edit() {
  $('.ui.dropdown').dropdown({ placeholder: false });

  $(".remove-avatar").off("click")
  $(".remove-avatar").on("click", function() {
    $("#user_remove_avatar").trigger("click")
    $(".avatar-preview").attr('src', $(".avatar-preview").attr("data-default-avatar"));
    return
  });

  $(".add-avatar").click(() => $(".add-avatar-file").click());

  const preview = $(".avatar-preview");
  return $(".add-avatar-file").change(function(event) {
    const input = $(event.currentTarget);
    const file = input[0].files[0];
    const reader = new FileReader();
    reader.onload = function(e) {
      const image_base64 = e.target.result;
      preview.attr("src", image_base64);
    };
    reader.readAsDataURL(file);
  });
 }
}

window.users = users;
