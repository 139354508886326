// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

import { Application } from "@hotwired/stimulus"
const application = Application.start()

import * as RequestJS from '@rails/request.js'
window.RequestJS = RequestJS
window.post = RequestJS.post
window.get = RequestJS.get

import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name.replace("controllers--", ""), controller.module.default)
})

Rails.start()
ActiveStorage.start()

import "./vendor/jquery"

import("./vendor/aos")
import("./vendor/fontawesome")

import 'fomantic-ui/dist/semantic'

import "./components/**/*"
