document.addEventListener("turbo:load", function() {
  if ($("#items .form.edit").length > 0) {
    items.form()
  }
});

var items = {
  form() {
    $('.ui.dropdown').dropdown({ placeholder: false })
  }
}

window.items = items;
