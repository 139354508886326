import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "searchForm" ]

  connect() {
    console.log("search")

    $('.search.button').off("click")
    $('.search.button').on("click", function() {
      $('.ui.search.accordion').accordion("toggle", 0);
    })

    $('#search .ui.search .dropdown').dropdown({ 
      fullTextSearch: true,
    });

    this.search()
  }

  search() {
    console.log("searching...")
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.searchFormTarget.requestSubmit()
    }, 200)
  }
}
