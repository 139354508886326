import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    //console.log("user")
    users.edit()
  }

  check_change_password_input() {
    if (this.inputTargets.every((t) => t.value.length > 0)) {
      $('.modal .approve.button').removeClass('disabled')
    } else {
      $('.modal .approve.button').addClass('disabled')
    }
  }

  show_change_password_modal() {
    $('.change_password.modal').modal({
      onApprove() {
        $('#change_password_form').trigger('submit')
      }
    }).modal('show')
  }

  show_passwordless_auth_modal() {
    $('.passwordless_auth.modal').modal({
      onApprove() {
        $('.passwordless_auth.modal .approve.button').addClass("loading")
        $('#webauthn-submit').trigger('click')
        return false
      },
      onHidden() {
        $('.passwordless_auth.modal .approve.button').removeClass("loading")
      }
    }).modal('show')
  }

  show_device_keys_modal() {
    const device_keys_modal = document.getElementById("manage_keys_modal")
    const modal = $('.manage_keys.modal')
    const remove_keys_modal = $(".remove.device.key.modal")

    device_keys_modal.reload()

    modal.modal({
      onShow() {
        modal.find(".corner.label").on("click", function() {
          const model_id = $(this).data("model-id")
          const user_id = $("#users").data('model-id')

          remove_keys_modal.modal({
            allowMultiple: false,
            onApprove() {
              $.ajax({
                url: `/users/${user_id}/device_key`,
                type: 'DELETE',
                dataType: 'json',
                data: {
                  model_id: model_id
                },
                success(_data, _textStatus) {
                  
                }
              })
            },
            onHidden() {
              remove_keys_modal.remove()
              device_keys_modal.reload()
            },
            onDeny: function() {
              modal.modal("show")
            },
          }).modal("show")
          
        })
      },
      onHidden() {
        modal.remove()
        device_keys_modal.reload()
      },
    }).modal("show")
  }

  showManageDevicesModal() {
    console.log("showManageDevicesModal")
    const manage_devices_modal = document.getElementById("manage_devices_modal")
    const modal = $('.manage_devices.modal')
    const otp_modal = $(".remove.otp.device.modal")

    modal.modal({
      onShow() {
        //users.add_second_factor()

        modal.find(".corner.label").on("click", function() {
          const model_id = $(this).data("model-id")
          const user_id = $("#users").data('model-id')

          if (modal.find(".corner.label").length == 1) {
            users.remove_second_factor()
          } else {
            otp_modal.modal({
              allowMultiple: false,
              onApprove() {
                $.ajax({
                  url: `/users/${user_id}/second_factor`,
                  type: 'DELETE',
                  dataType: 'json',
                  data: {
                    model_id: model_id
                  },
                  success(_data, _textStatus) {
                    users.api_keys()
                  }
                })
              },
              onHidden() {
                otp_modal.remove()
                users.api_keys()
                
                manage_devices_modal.reload()
              },
              onDeny: function() {
                modal.modal("show")
              },
            }).modal("show")
          }
        })
      },
      onHidden() {
        modal.remove()
        manage_devices_modal.reload()
      },
    }).modal('show')
  }
}
